.bg-page{
    background-color: white;
    padding: 0px 80px
}
.listCate{
  position: sticky;
    top: 86px;
    background: white;
    height: 56px;
    z-index: 9;
}
.container{
    // background: #f3f3f8;
    height: calc(100vh - 7rem);
    // padding: 21px;
    // border-radius: 21px;
    overflow: auto;
}
.container-1{
    // background: #f3f3f8;
    height: calc(100vh - 10rem);
    // padding: 21px;
    // border-radius: 21px;
    overflow: auto;
}

/*edit, more time, transaction*/
.product-icons {
  display: none;
}

.product-image img {
  background-color: rgb(var(--light-rgb));
}
.product-card {
  position: relative;
}
.product-card:hover .product-icons {
  display: block;
  right: 0;
}
.product-card:hover .product-icons .edit,
.product-card:hover .product-icons .moretime,
.product-card:hover .product-icons .transaction {
  position: absolute;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-card:hover .product-icons .edit i,
.product-card:hover .product-icons .moretime i,
.product-card:hover .product-icons .transaction i {
  font-size: 0.9rem;
}
.product-card:hover .product-icons .edit {
  background-color: white;
  color: black;
  inset-block-start: 0.75rem;
  inset-inline-end: 0.75rem;
}
.product-card:hover .product-icons .moretime {
  background-color: white;
  color: black;
  inset-block-start: 5.25rem;
  inset-inline-end: 0.75rem;
}
.product-card:hover .product-icons .transaction {
  background-color: white;
  color: black;
  inset-block-start: 3rem;
  inset-inline-end: 0.75rem;
}

// Customer Page.
.option{
  opacity: 0.36;
}
.option:hover{
  opacity: 1;
}



/** Page products mobile */
.cateProducts{
  overflow-x: auto;
}
.cateProducts::-webkit-scrollbar{
    display: none; /* Ẩn thanh trượt ngang */
}

.wrapper_search_cate{
    position: sticky;
    top: 52px;
    z-index: 19;
    background: white;
    padding: 9px 0;
}
/** *Page products mobile*/