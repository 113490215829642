.h-56{
    height: 56px;
}
.height-109{
    height: 109px;
}
.height-300{
    height: 300px !important;
}
.border-1{
    border: 1px solid #d5d5d5
}
.font-21{
    font-size: 21px;
}
.no-overflow {
    overflow: inherit;
}
.box-shadow{
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
}

.bulkhead::after{
    content: "";
    display: block;
    background-color: #d5d5d5;
    width: 1px;
    height: 56px;
    position: absolute;
    top: 0px;
    right: 0;

}
.bulkheadButtom::after{
    content: "";
    display: block;
    background-color: #d5d5d5;
    width: 100%;
    height: 1px;
}


/** ===== detail Estate =====*/
.container_wrapper{
    display: flex;
    min-height: 100vh;
}
.content_wrapper{
    // padding: 0 44px 60px;
    flex: 1 1;
    max-width: 100%;
}
.bg-none{
    background-color: inherit;
}
.hide{
    display: none;
}

.formBooking{
    padding: 18px;
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
    position: sticky;
    top: 105px;
}

// .discriptionDetail p{
//     font-size: 17px;
// }

// quảng cáo (advertesement)
.wrapper_adEs{
    position: absolute;
    left: 12px;
    bottom: 21px;
    background: rgb(255 255 255 / 89%);
    padding-right: 6px;
    border-radius: 9px;
}
.content_adEs h6, p{
    color: black;
}
.button_adEs{
    height: 30px;
    background-color: #2793e6;
    color: white;
    border-radius: 6px;
    font-weight: 500;
    line-height: 30px;
    padding: 0px 9px;
}
.cancel_adEs{
    position: relative;
}
.cancel_adEs button{
    position: absolute;
    top: -6px;
    right: -7px;
    border-radius: 21px;
}

.discriptionDetail p span{
    font-size: 18px !important;
}
/** ===== *detail Estate =====*/