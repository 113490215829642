.buttonAddImage{
    height: 28px;
    font-size: 21px;
    cursor: pointer;
    bottom: 0px;
    background: rgb(141 141 141 / 28%);
    color: black;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.border-1{
    border:1px dashed #c4c4c4a1 
}