.chat-list-inner {
  display: flex;
  align-items: flex-start;
  // max-width: 91%;
}
.chatting-user-info {
  color: var(--default-text-color);
  font-weight: 600;
  font-size: 1.2rem;
}

.send{
    position: absolute;
    right: 16px;
    top: 50%;
    height: 26px;
    transform: translate(-20%, -50%);
}
// #messengeChat {
//     resize: none; /* Ngăn người dùng thay đổi kích thước của textarea */
//     overflow: hidden; /* Ẩn nội dung vượt ra ngoài textarea */
//     padding-right: 21px;
// }
textarea {
    word-wrap: break-word;
    padding-right: 3.3rem !important;
    resize: none;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    overflow-y: hidden; /* Loại bỏ thanh trượt dọc */
}