.suggest{
    min-width: 186px !important;
    overflow: auto !important;
    max-height: 300px;
    position: absolute;
    z-index: 9;
    top: 6px;
}
.suggestDis li:hover{
    background-color: rgb(240 240 240);
    padding: 0 6px;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 600;
}
.filterResult{
    max-height: 430px;
    overflow: auto;
}
.cateEstate{
    background-color: rgb(255 255 255 / 86%);
    top: 3px;
    left: 3px;
    padding: 0 6px;
    border-radius: 12px;
    font-size: 11px;
    font-weight: 600;
}