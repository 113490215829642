// List user
.form-check-input-1:checked{
    opacity: 0.86;
}
.border-1{
    opacity: 0.68;
    border: 1px solid #b9b9b9;
}

.bg-set{
    background: #f4f6f8;
}
.center{
    position: relative;
    left: 50%;
    transform: translate(-15%);
}

/* candidate List*/
.avatar-lg{
    width: 6rem;
    height: 6rem;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}