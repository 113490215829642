

// Layout Left Right

/** sidebar content*/
.Sidebar_Content {
  display: flex;
  min-height: 100vh;
}
.Sidebar_Wrap {
  flex-shrink: 0;
}
.Sidebar_Wrapper {
  --width: 96px;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  left: 0;
  padding: 0 8px;
  position: -webkit-sticky;
  position: sticky;
  top: 114px;
  width: var(--width);
  z-index: 8;
}
.ListCategory {
  position: sticky;
  right: 0;
  top: 86px;
  z-index: 11;
  background: white;
  padding-bottom: 9px;
}
.AddProduct {
  align-items: center;
  background-color: #3a77f8;
  border-color: #1473e6;
  opacity: 0.86;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  margin: 8px 0 2px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 44px;
}
.fs-33 {
  font-size: 33px;
}
.IconAdd {
  transition: transform 0.3s ease; /* Thêm hiệu ứng xoay mềm mại */
}
.IconAdd:hover {
  transition: transform 0.3s ease; /* Thêm hiệu ứng xoay mềm mại */
}

.IconAdd:hover {
  transform: rotate(46deg); /* Xoay 360 độ */
}
.Sidebar_list {
  padding-left: 0;
}
.Sidebar_item {
  align-items: center;
  background-color: #fff;
  border-radius: 16px;
  // color: var(--color-purple-pastel);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 72px;
  justify-content: center;
  margin-top: 4px;
  width: 72px;
}
.Sidebar_item:hover {
  background-color: #e8ebed;
  color: #1a1a1a;
}
.ContentPro {
  max-width: calc(100% - 96px);
  padding: 0 0px 0 86px;
}
//* Layout Left Right

.buttonSave {
  background-color: inherit;
  border: none;
}
.buttonSave:hover {
  color: white;
}
