
.wrapper{
    height: 74px;
    // border-bottom: 1px solid rgb(143, 142, 142);
    display: flex;
    justify-content: center;
}

.content{
    height: 100%;
    width: 1425px;
    padding: 0px 80px;
}

.w_168{
    max-width: 168px;
}

.appSidebar{
    background: var(--menu-bg);
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    z-index: 10;
    transition: all 0.05s ease;
    height: 74px;
}
.cate{
    margin-right: 33px;
}

// notification calendar (booking)
.notifiCalendar{
    position: absolute;
    right: 0px;
    color: white;
    opacity: 0.86;
    background: red;
    width: 21px;
    text-align: center;
    border-radius: 21px;
}

// Header home
.NavBar_Wrapper {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e8ebed;
  display: flex;
  font-size: 1.4rem;
  height: 86px;
  left: 0;
  padding: 0 12px 0px 86px;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 20;
}
.w_168 {
  max-width: 168px;
}
.Navbar_LoginRegister {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.Navbar_LoginRegister,
.Navbar_Search,
.Navbar_Logo {
  flex: 1 1;
}
.Navbar_Login {
  background: transparent;
  cursor: pointer;
  font-weight: 500;
  margin-right: 30px;
  border: none;
  font-size: 15px;
}
.Navbar_Register {
  background: linear-gradient(to right bottom, #ff8f26, #ff5117);
  border-radius: 99px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  padding: 9px 20px;
  transition: opacity 0.25s;
  border: 1px solid white;
}

// Page Home 2
.NavBar_Wrapper_2{
  left: 0;
  // padding: 0 12px 0px 12px;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  top: -35px;
  z-index: 20;
}
.Navbar_Wrapper_Contact{
  align-items: center;
  background-color: #fff;
  // border-bottom: 1px solid #e8ebed;
  display: flex;
  font-size: 1.4rem;
  height: 35px;
  padding: 0 86px 0px 86px;
  
}
.NavBar_Wrapper_Home {
  align-items: center;
  background-color: #fff;
  display: flex;
  font-size: 1.4rem;
  height: 86px;
  left: 0;
  padding: 0 12px 0px 86px;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 20;
}
.wrapper_contact_left a{
  transition: all .2s ease-in-out;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.contact-1, .contact-2, .contact-3{
  position: relative;
}
.wrapper_contact_left .contact-1:before, .contact-2:before {
  content: "";
  width: 2px;
  height: 15px;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #e0e0e0;
  position: absolute;
  right: 0;
  left: auto;
  margin: auto;
  top: 0;
  bottom: 0;
}
.bg_icon_header{
    background: #e6e6e6;
    padding: 9px;
    border-radius: 21px;
    font-size: 36px;
}

// Header dashboard
.wrapper_header{
  position: sticky;
  background: white;
  top: 0;
  z-index: 29;
  padding: 0 80px;
  // height: 86px;
}