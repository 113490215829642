.no-border{
    border: 0px
}

.bulkhead::after{
    content: "";
    display: block;
    background-color: #d5d5d5;
    width: 1px;
    height: 23px;
    position: absolute;
    top: -12px;
    right: 0;

}
.border-1{
    border:1px solid #d5d5d5
}
.no-focus:focus{
    outline: none;
}
.width-35{
    width: 35px;
    height: 35px;
}

/* search simple */
.search{
    width: 100%;
}
.searchSimple{
    border-radius: 21px 0px 0px 21px;
    border: 1px solid #e6e6e6;
    padding: 9px;
    border-right: none;
    width: 85%;
}
.searchSimple:focus{
    outline: none;
    
}
.searchIcon{
    border-radius: 0px 21px 21px 0px;
    border: 1px solid #e6e6e6;
    padding: 9px 15px;
    width: 15%;
}

.searchData{
    height: 100%;
}
.searchSimple-2{
    border-radius: 3px 0px 0px 3px;
    border: 1px solid #e6e6e6;
    padding: 9px;
    border-right: none;
    width: 85%;
    height: 100%;
}
.searchSimple-2:focus{
    outline: none;
    
}
.searchIcon-2{
    border-radius: 0px 3px 3px 0px;
    border: 1px solid #e6e6e6;
    padding: 9px 15px;
    width: 15%;
    height: 100%;
}
// Search horizontal.
.Search_Wrapper_horizontal{
    border: 2px solid #e8e8e8;
    flex: 1 1;
    height: 50px;
    padding: 0 16px 0 8px;
    transition: border-color .2s ease-in-out;
}

// Search home.
.Search_Wrapper{
    border: 2px solid #e8e8e8;
    border-radius: 20px;
    flex: 1 1;
    height: 40px;
    padding: 0 16px 0 8px;
    transition: border-color .2s ease-in-out;
}
.SearchIcon{
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 18px;
    border-radius: 50%;
    // height: 38px;
    opacity: .7;
    transition: opacity .2s ease-in-out;
    width: 30px;
    transform: translate(10px, -7%);
}
.Search_ClearText{
    cursor: pointer;
    padding: 4px 0 4px 4px;
    opacity: 0.7;
}
.Search_Input{
    border: none;
    caret-color: #444;
    flex: 1 1;
    height: 100%;
    outline: none;
    padding: 0 4px;
    background: transparent;
    font-size: 15px;
}
.resultSearch{
    // animation: Tippy-module_fadeIn__2LeID .3s ease;
    background: white;
    border-radius: 10px;
    box-shadow: 0 -4px 32px rgba(0,0,0,.2);
    color: #1d2129;
    margin: 0;
    overflow: hidden;
    padding-left: 0;
    transition: all .3s ease-in-out;
    will-change: opacity, transform;
    position: absolute;
    margin-top: 9px;
}
.contentSearch{
    color: var(--desc-color);
    font-size: 1.4rem;
    max-height: calc(90vh - 66px);
    min-height: 50px;
    min-width: 230px;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding: 12px 24px;
    width: 445px;
}
.searchTitle{
    align-items: center;
    display: flex;
    padding: 6px 0 0;
    font-size: 14px;
    opacity: 0.6;
}
.Search_heading{
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    padding: 24px 0 12px;
}
.Search_heading h5{
    color: #333;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}
.Search_heading span{
    color: #0000008a;
    font-size: 16px;
}

.contentSearch h5{
    font-size: 16px;
    font-weight: 400;
}
.contentSearch p{
    font-size: 14px;
    opacity: 0.7;
}
.images-search{
    min-width: 55px;
    max-width: 55px;
    height: 55px;
    border-radius: 100px;
}

// Search two input.
.Search_Wrapper_horizontal{
    border: 2px solid #e8e8e8;
    flex: 1 1;
    padding: 0 16px 0 8px;
    transition: border-color 0.2s ease-in-out;
}

.SearchIcon{
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 18px;
    border-radius: 50%;
    // height: 38px;
    opacity: .7;
    transition: opacity .2s ease-in-out;
    width: 30px;
    transform: translate(15%, -7%);
}
.vach{
    content: "";
    width: 2px;
    height: 21px;
    margin: auto;
    background: #e0e0e0;
    border-radius: 9px;
}
