.font{
    font-size: 0.7rem;
}
.font-1{
    font-size: 1rem;
}
.font-2{
    font-size: 1.5rem;
}
.font-3{
    font-size: 2rem;
}
.font-4{
    font-size: 2.5rem;
}
.bg-red{
    background: red;
    opacity: 0.68;
    padding: 6px;
    border-radius: 9px;
    color: white;
}
.colorW{
    color: white;
}
