.dropdown{
    border-color: var(--default-border);
    box-shadow: 0 .25rem .625rem rgba(20,20,20,.1);
    font-size: .875rem;
    color: #485056;
    background-color: var(--custom-white);
    padding: 3px;
    border-radius: 12px;
    min-width: 363px;
    overflow: auto;
    height: calc(100vh - 6rem);
}