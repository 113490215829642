.warpper{
    align-items: center;
    background-color: #fff;
    border-radius: 16px;
    color: #404040;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 72px;
    justify-content: center;
    margin-top: 4px;
    width: 79px;
    font-weight: 500;
}
.active{
    font-weight: 600 !important;
    color: #485056;
    position: relative;
    padding-bottom: 6px;
}
.active::before{
    content: "";
    position: absolute;
    inset-inline-start: 0%;
    inset-inline-end: 0;
    inset-block-end: 0;
    margin-top: 3px;
    width: 100%;
    height: 0.175rem;
    background-color: var(--bs-body-color);
    border-radius: 50px;
}