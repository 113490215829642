
@media (max-width: 600px) {
    .container, .container-sm {
        max-width: 500px;
    }
}
// Tắt nút next cho slider.
.sliderImageMobile .slick-prev{
  display: none !important;
}
.sliderImageMobile .slick-next{
  display: none !important;
}
// chỉnh width 100% cho thư viện lazyloadimages.
.lazy-load-image-background{
  width: 100%;
}
// Chỉnh ảnh ra giữa tâm
.slide-container-image{
  width: 100%;
  height: 600px; /* Chiều cao cố định cho container */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Đảm bảo không có phần dư của hình ảnh vượt ra ngoài container */
}

// Kích thước > 1300
.fh{
    height: 283px;
}
.text-left{
  text-align: left;
}
.mt-6{
  margin-top:4rem
}

// text color icon xác thực.
.text-reputation{
  color: #2793e6 !important;
}


// Chỉnh kích thước màn hình 1300 trở xuống.
@media screen and (min-width: 1100px) and (max-width: 1300px) {
  .fh{
    max-height: 250px;
  }
  .fontSize{
    font-size: .91rem
  }
  .fontSize-1{
    font-size: .81rem
  }
  .limitText{
    max-width: 230px; /* Độ rộng của phần chứa */
    white-space: nowrap; /* Ngăn chữ tràn xuống dòng mới */
    overflow: hidden; /* Ẩn nội dung vượt ra ngoài kích thước phần chứa */
    text-overflow: ellipsis; /* Thêm dấu ba chấm ở cuối nếu chữ tràn */
  }
  .limitText-2{
    max-width: 230px; /* Độ rộng của phần chứa */
    overflow: hidden; /* Ẩn nội dung vượt ra ngoài kích thước phần chứa */
    white-space: pre-line; /* Cho phép xuống dòng */
  }
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Số dòng tối đa */
    -webkit-box-orient: vertical;
  }
  .hs{
    height: calc(100vh - 9rem);
    overflow: auto;
  }
}

// Format font nội dung mô tả trên mobile.
#discriptionDetailModal ul li span{
  font-family: view360, sans-serif !important;
  font-size: inherit !important;
}