.mobile_wrapper{
    min-height: 100vh;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}
.app_content{
    padding: 0px 12px;
}


// Header.
.nameLogo{
    font-size: 21px;
    color: #007bff;
    font-weight: 600;
}
.logoMobile{
    width: 100px;
    background: #007bff;
    padding: 3px;
    border-radius: 9px;
    opacity: 0.86;
}
.headerMobile{
    // position: fixed;
    position: sticky;
    top: -1px;
    background: white;
    z-index: 29;
    height: 50px;
    width: 100%;
}
.Wrapper_HeaderMobile{
    padding: 0px 12px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 29;
    padding-bottom: 9px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 500px;
}
.headerMobileDetail{
    position: sticky;
    top: -1px;
    background: white;
    z-index: 29;
    height: 50px;
    width: 100%;
}

// List cate home mobile.
.listCateHomeMobile{
    overflow-x: auto;

}
.listCateHomeMobile::-webkit-scrollbar{
    display: none; /* Ẩn thanh trượt ngang */
}



// List item estate.
.containerItem{
    // overflow: hidden auto;
    // height: calc(100vh)
    margin-bottom: 91px;
}
.containerItem::-webkit-scrollbar{
    display: none;
}
.estateCate{
    background-color: white;
    border-radius: 21px;
    display: flex;
    align-items: center;
    padding: 0 9px;
}
.buttonSave{
    border: 1px solid white;
    border-radius: 21px;
    background-color: white;
    padding: 3px 6px;
}
// *List item estate.


/** header mobile */
.appHeader{
    height: 56px;
    position: fixed;
    // position: sticky;
    // position: -webkit-sticky;
    top: -1px;
    left: 0;
    right: 0;
    z-index: 20;
    background: #FFFFFF;
    color: #27173E;
    border-bottom: 1px solid #DCDCE9;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
.appHeader .left, .appHeader .right {
    height: 56px;
    display: flex;
    align-items: center;
    position: absolute;
}
.appHeader .left {
    left: 10px;
    top: 0;
}
.appHeader .pageTitle {
    font-size: 17px;
    font-weight: 500;
    padding: 0 10px;
}
.appHeader .right {
    right: 10px;
    top: 0;
}
.appCapsule{
    padding: 0px 10px;
    margin-bottom: env(safe-area-inset-bottom);
    margin-top: env(safe-area-inset-top);
}
.wrapper_header{
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
}
.imageNoti{
    border: 1px solid #ff00006e;
    border-radius: 9px;
}
.goBack{
    display: flex;
    align-items: center;
    height: 15px;
}
.countNotifiMobi{
    position: absolute;
    background: #ff4e46;
    padding: 0 6px;
    border-radius: 100px;
    color: white;
    right: -4px;
    top: -4px;
    font-size: 12px;
    border: 1px solid;
}

/** *header mobile */

/** page home*/
.wrapper_button_detail_estate{
    background: #efefef80;
    max-height: 68px;
}
.wrapper_button_detail_estate button{
    background-color: #2793e6;
    color: white;
    height: 36px;
}


// Search home.
.wrapper_searchHome{
    position: sticky;
    top: 45px;
    background: white;
    z-index: 20;
    height: 63px;
    padding: 6px 0;
}
.Search_Wrapper{
    border: 2px solid #e8e8e8;
    border-radius: 20px;
    flex: 1 1;
    height: 40px;
    padding: 0 16px 0 8px;
    transition: border-color .2s ease-in-out;
}
.SearchIcon{
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 18px;
    border-radius: 50%;
    // height: 38px;
    opacity: .7;
    transition: opacity .2s ease-in-out;
    width: 30px;
    transform: translate(15%, -7%);
}
.Search_ClearText{
    cursor: pointer;
    padding: 4px 0 4px 4px;
    opacity: 0.7;
}
.Search_Input{
    border: none;
    caret-color: #444;
    flex: 1 1;
    height: 100%;
    outline: none;
    padding: 0 4px;
    background: transparent;
    font-size: 15px;
}
.resultSearch{
    // animation: Tippy-module_fadeIn__2LeID .3s ease;
    background: white;
    border-radius: 10px;
    box-shadow: 0 -4px 32px rgba(0,0,0,.2);
    color: #1d2129;
    margin: 0;
    overflow: hidden;
    padding-left: 0;
    transition: all .3s ease-in-out;
    will-change: opacity, transform;
    position: absolute;
    margin-top: 9px;
    width: 100%;
    z-index: 29;
}
.contentSearch{
    color: var(--desc-color);
    font-size: 1.4rem;
    max-height: calc(90vh - 66px);
    min-height: 50px;
    // min-width: 230px;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding: 12px 24px;
    // width: 445px;
}
.searchTitle{
    align-items: center;
    display: flex;
    padding: 6px 0 0;
    font-size: 15px;
    font-weight: 500;
    opacity: 0.6;
}
.Search_heading{
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    padding: 24px 0 12px;
}
.Search_heading h5{
    color: #333;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}
.Search_heading span{
    color: #0000008a;
    font-size: 16px;
}


.contentSearch p{
    opacity: 0.7;
}
.images-search{
    min-width: 55px;
    max-width: 55px;
    height: 55px;
    border-radius: 100px;
}

// *Search home.


// Search two page home.
.hideSearchTwoPageHome{
    // position: sticky;
    // // top: -11px;
    // background: white;
    // z-index: 0;
    // height: 63px;
    // padding: 6px 0;
}


/** page Dashboard */
.wrapper_dash{
    // padding: 10px;
    margin-bottom: 98px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}
.box-cate{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // grid-gap: 20px 12px;
}
.box-cate li a {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
}
.box-cate li .icon-box {
    width: 44px;
    height: 44px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.box-cate li .icon-box-1 {
    width: 35px;
    height: 35px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.box-cate li button{
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
}
.box-cate .buttonCate button{
    width: 94.5px;
}
.bg-cate-1{
    background: rgba(198, 242, 178, 0.5);
}
.bg-cate-2{
    background-color: rgba(199, 238, 247, 0.5);
}
.bg-cate-3{
    background-color: rgba(241, 242, 178, 0.5);
}
.bg-cate-4{
    background-color: rgba(244, 204, 181, 0.5);
}
/** *page Dashboard */


/** Page calendar */
.cateHorizontal{
    overflow-x: auto;
    position: sticky;
    top: 52px;
    background: white;
    z-index: 19;
    padding: 9px 0;
}
.cateHorizontal::-webkit-scrollbar{
    display: none;
}
.itemBooking{
    box-shadow: 0px 0px 2px 0px #e1e1e1;
    border-radius: 9px;
    padding: 6px;
}
.detailBooking{
    width: 35px;
    height: 35px;
    transform: translate(50%, 40%);
    border-radius: 21px !important;
}
/** *Page calendar */

/** Page customer */
.bg-cus-menu{
    position: sticky;
    top: 52px;
    background: white;
    padding: 9px 9px;
    z-index: 29;
}
.optionMenu{
    // display: flex;
    // background: rgb(244 246 248);
    // padding: 6px;
    // border-radius: 6px;
    // justify-content: flex-end;
}
.itemCustomer{
    box-shadow: 0px 0px 2px 0px #e1e1e1;
    border-radius: 9px;
    padding: 6px;
}
.iconBuy, .iconRent{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    text-align: center;
    line-height: 50px;
    color: white;
    font-weight: 500;
}
.btn-iconBuy{
    background-color: #0289ff !important;
    color: white !important;
}
.btn-iconRent{
    background-color: #0dd185 !important;
    color: white !important;
}
.addCustomer{
    position: absolute;
    bottom: 120px;
    right: 19px;
}
.addCustomer button{
    background-color: #0dd185 !important;
    color: white !important;
    padding: 9px 12px;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    box-shadow: 0 1rem 1.125rem rgba(169, 169, 169, 0.15);
}
.cancelSearch{
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-1px, 10px);
}
// /** *Page customer */

/** Page pro like */
.bg-itemPro{
    background-color: #e1e1e12b;
    box-shadow: 0px 0px 2px 0px #e1e1e1;
    border-radius: 9px;
}
.itemProLike{
    padding: 6px;
}
.itemProLike img{
    border-radius: 50px;
    border: 1px solid white;
}
/** *Page pro like */

/** Menu footer */
.menu_footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    height: 86px;
    left: 0;
    padding: 9px;
    z-index: 9;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    max-width: 500px;
    left: auto;
    right: auto;
}
.menu_footer a{
    width: 59px;
}
.activeMenu{
    color: #007bff !important;
}
.webApp{
    position: absolute;
    top: -51px;
}
.webApp button{
    background: rgb(251 39 39 / 47%);
    color: white;
    border: 3px solid white;
}
/** Menu footer */