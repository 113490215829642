// quảng cáo (advertesement)
.wrapper_adEs{
    position: absolute;
    left: 12px;
    bottom: 21px;
    background: rgb(255 255 255 / 89%);
    padding-right: 6px;
    border-radius: 9px;
}
.content_adEs h6, p{
    color: black;
}
.button_adEs{
    height: 30px;
    background-color: #2793e6;
    color: white;
    border-radius: 6px;
    font-weight: 500;
    line-height: 30px;
    padding: 0px 9px;
}
.cancel_adEs{
    position: relative;
}
.cancel_adEs button{
    position: absolute;
    top: -6px;
    right: -7px;
    border-radius: 21px;
}
