// payment
.contentPayMenu{
    background-color: white;
}

.iconCate{
    color: #6f6f6f;
    opacity: 0.68;
    cursor: pointer;
}
.active{
    color: #151515;
    opacity: 1;
}

.paymentMain, .paymentRight{
    overflow: hidden;
    height: calc(100vh - 9rem);
}

.contentPayMain, .contentPayRight{
    background-color: white;
    width: 100%;
    height: inherit;
}
.notifi{
    top: -5px;
    right: -13px;
    background: red;
    color: white;
    padding: 0px 7px;
    border-radius: 50px;
}