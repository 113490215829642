// manager path.
.containerMenu{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #f3f3f3;
    padding: 9px;
}

.menuLeft{
    width: 30%;
    
}
.contentLeft, .contentRight {
    border-radius: 6px;
    height: calc(100vh - 12rem);
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: var(--bs-border-radius-a);
    background: white;
}
.menuRight{
    width: 68%;
}

.border-1{
    border:1px solid rgb(233 237 246);
}