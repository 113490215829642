.wrapper{
    max-height: calc(100vh - 11.9rem);
    height: 600px;
}
.loading{
    background: #00000061;
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading_center{
    position: relative;
    top: 50%
}

// Loading text
.loadingText{
    position: absolute;
    bottom: -23px;
    left: 50%;
    transform: translate(-50%);
    background: red;
    padding: 3px 21px;
    border-radius: 6px;
    opacity: 0.68;
    color: white;
}